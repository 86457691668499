import styled from "@emotion/styled";
import { fontRoboto, theme } from "@product/scmp-sdk";

export const Container = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;

  min-inline-size: 168px;

  ${theme.breakpoints.up("desktop")} {
    min-inline-size: 200px;
  }
`;

export const DateWeatherAndBackToOldScmpButton = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
`;

export const DateWeather = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
`;

export const DisplayDate = styled.div`
  color: #ffffff;
  font-size: 12px;

  b {
    display: inline-block;

    margin-inline-end: 4px;
  }
`;

export const BackToOriginalScmpButton = styled.div`
  inline-size: fit-content;
  padding-block: 8px;
  padding-inline: 12px;

  color: #001246;
  font-weight: 700;
  font-size: 12px;
  font-family: ${fontRoboto};
  font-style: normal;
  line-height: normal;

  background-color: #ffca05;

  cursor: pointer;
`;

export const HeaderWeatherContainer = styled.div`
  inline-size: 56px;
`;
