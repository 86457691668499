import type { FunctionComponent } from "react";

import { Icon, WarningRainBlackIcon } from "./styles";

type IconMap = {
  [key: string]: {
    color?: string;
    content: string;
  };
};

type Props = {
  className?: string;
  name: string;
  title?: string;
};

const iconMap: IconMap = {
  "1": { content: "Z" },
  "2": { content: "a" },
  "3": { content: "b" },
  "4": { content: "c" },
  "5": { content: "d" },
  "6": { content: "e" },
  "7": { content: "f" },
  "8": { content: "g" },
  "9": { content: "h" },
  "10": { content: "i" },
  "11": { content: "j" },
  "12": { content: "k" },
  "13": { content: "l" },
  "14": { content: "m" },
  "15": { content: "n" },
  "16": { content: "o" },
  "17": { content: "p" },
  "18": { content: "q" },
  "19": { content: "r" },
  "20": { content: "s" },
  "21": { content: "t" },
  "22": { content: "u" },
  "23": { content: "v" },
  "24": { content: "w" },
  "25": { content: "x" },
  "26": { content: "y" },
  "27": { content: "z" },
  "28": { content: "A" },
  "29": { content: "B" },
  "30": { content: "C" },
  "31": { content: "D" },
  "32": { content: "E" },
  "33": { content: "F" },
  "34": { content: "G" },
  "signal-t1": { content: "H" },
  "signal-t3": { content: "I" },
  "signal-t8-ne": { content: "J" },
  "signal-t8-nw": { content: "K" },
  "signal-t8-se": { content: "L" },
  "signal-t8-sw": { content: "M" },
  "signal-t9": { content: "N" },
  "signal-t10": { content: "O" },
  "warning-cold": { color: "#80B1C9", content: "P" },
  "warning-fire-red": { color: "#EB4254", content: "Q" },
  "warning-fire-yellow": { color: "#FFCA05", content: "Q" },
  "warning-frost": { content: "R" },
  "warning-landslip": { content: "S" },
  "warning-ntfl": { content: "T" },
  "warning-rain-amber": { color: "#FFCA05", content: "U" },
  "warning-rain-black": { color: "#000", content: "U" },
  "warning-rain-red": { color: "#EB4254", content: "U" },
  "warning-sms": { content: "V" },
  "warning-ts": { content: "W" },
  "warning-tsunami": { content: "X" },
  "warning-vhot": { content: "Y" },
};

export const HeaderWeatherIcon: FunctionComponent<Props> = ({ className, name, title }) => {
  if (name === "warning-rain-black") return <WarningRainBlackIcon />;
  const icon = iconMap[name];

  if (!icon) return null;

  const { color, content } = icon;

  return <Icon $color={color} $content={content} className={className} title={title} />;
};

HeaderWeatherIcon.displayName = "HeaderWeatherIcon";
