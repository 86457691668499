import { NoSsr } from "@mui/base";
import { theme, useResponsive } from "@product/scmp-sdk";
import { useSetAtom } from "jotai";
import type { FunctionComponent } from "react";
import { useEffect, useTransition } from "react";

import { useEditionValue } from "shared/lib/edition";
import { useGetHomeAsPath } from "shared/lib/router";

import { useIsScrollingOverAppBarThreshold } from "scmp-app/components/app-bar";
import { HideElementOnScrollEffectThreshold } from "scmp-app/components/app-bar/consts";
import { useAppBarContext } from "scmp-app/components/app-bar/contexts";
import { useAppBarSetStickyScrollHeight } from "scmp-app/components/app-bar/hooks/sticky/app-bar-set-sticky-scroll-height";
import { IdleQueue } from "scmp-app/components/common/idle-queue";
import { hamburgerMenuAtom } from "scmp-app/components/hamburger-menu/atoms";
import IconMenu from "scmp-app/components/header/header-icon/menu.svg";
import type { HeaderConfiguration } from "scmp-app/components/header/types";
import { HomeEdition } from "scmp-app/components/home/edition";
import { sendGA4Tracking } from "scmp-app/components/tracking/google-analytics-4/apis";
import { yieldToMain } from "scmp-app/lib/yield";
import { page as HomePage } from "scmp-app/pages/home";

import { DynamicHomeNewsTicker } from "./dynamics";
import { useHomeHeader } from "./hooks";
import {
  ActionButton,
  Container,
  Content,
  HeaderContainer,
  HomeHeaderContainer,
  LeftFeatureContainer,
  OnelineMenuContainer,
  RightFeatureContainer,
  Spinner,
  StyledDynamicHeaderOnelineMenuContainer,
  StyledHomeMiniHeader,
  StyledLogo,
  StyledLogoLink,
  Wrapper,
} from "./styles";

export type Props = {
  className?: string;
  headerConfiguration?: HeaderConfiguration;
};

export const HomeHeader: FunctionComponent<Props> = ({ className, headerConfiguration }) => {
  const { setShouldSwap } = useAppBarContext();
  const edition = useEditionValue();
  const homeAsPath = useGetHomeAsPath();
  const { reference } = useAppBarSetStickyScrollHeight();
  const setHamburgerMenuState = useSetAtom(hamburgerMenuAtom);

  const isDesktopUp = useResponsive(theme.breakpoints.up("desktop"));
  const threshold = isDesktopUp
    ? HideElementOnScrollEffectThreshold.desktop
    : HideElementOnScrollEffectThreshold.mobile;
  const shouldSwap = useIsScrollingOverAppBarThreshold(threshold);
  const { mastheadQueryParameter, renderFeatures } = useHomeHeader(
    shouldSwap ?? false,
    headerConfiguration,
  );

  const { pathname: homePagePathname, query: homePageQuery } = HomePage.route({
    edition,
    slide: "main",
  });

  useEffect(() => {
    if (!setShouldSwap) return;
    setShouldSwap(shouldSwap ?? false);
  }, [setShouldSwap, shouldSwap]);

  const [isLoading, startTransition] = useTransition();

  return (
    <>
      <Container className={className}>
        <HomeHeaderContainer ref={reference}>
          <HomeEdition />
          <HeaderContainer>
            <Wrapper>
              <NoSsr>
                <DynamicHomeNewsTicker />
              </NoSsr>
              <Content>
                <LeftFeatureContainer>
                  <IdleQueue>
                    <ActionButton
                      $responsiveVariants={{
                        desktopUp: "hidden",
                        largeDesktopUp: "hidden",
                        mobileUp: "show",
                        tabletUp: "show",
                      }}
                      onClick={async () => {
                        await yieldToMain({ priority: "user-blocking" });
                        startTransition(() => {
                          setHamburgerMenuState(current => ({
                            ...current,
                            isOpen: !current.isOpen,
                          }));
                        });

                        await yieldToMain({ priority: "background" });
                        sendGA4Tracking({
                          action: "click",
                          category: "menu",
                          customized_parameters: {
                            action_type: "open",
                            edition,
                            menu_type: "hamburger",
                          },
                          subcategory: "section",
                        });
                      }}
                    >
                      {isLoading ? <Spinner /> : <IconMenu />}
                    </ActionButton>
                  </IdleQueue>
                  {renderFeatures("left")}
                </LeftFeatureContainer>

                <StyledLogoLink
                  anchorProps={{ "aria-label": "SCMP homepage" }}
                  as={homeAsPath}
                  pathname={homePagePathname}
                  query={{ ...homePageQuery, ...mastheadQueryParameter }}
                >
                  <StyledLogo />
                </StyledLogoLink>
                {shouldSwap === false ? (
                  <RightFeatureContainer>{renderFeatures("right")}</RightFeatureContainer>
                ) : null}
              </Content>
              <OnelineMenuContainer>
                <NoSsr>
                  <StyledDynamicHeaderOnelineMenuContainer />
                </NoSsr>
              </OnelineMenuContainer>
            </Wrapper>
          </HeaderContainer>
        </HomeHeaderContainer>
      </Container>

      <StyledHomeMiniHeader $shouldSwap={shouldSwap} />
    </>
  );
};

HomeHeader.displayName = "HomeHeader";
