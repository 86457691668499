import { theme, useResponsive } from "@product/scmp-sdk";
import type { FunctionComponent } from "react";
import { ErrorBoundary } from "react-error-boundary";

import { ClientSideSuspense } from "shared/components/common/client-side-suspense";
import { useEditionValue } from "shared/lib/edition";
import { Edition as EditionEnum } from "shared/lib/edition/data";
import { formatInTimeZone } from "shared/lib/utils";

import { HeaderWeather } from "scmp-app/components/home/header-weather";

import { Container, DisplayDate } from "./styles";

type Props = {
  className?: string;
};

export const DateWeather: FunctionComponent<Props> = ({ className }) => {
  const edition = useEditionValue();
  const dateFormat = "MMMM dd, yyyy";
  const dateFormatShort = "MMM dd, yyyy";
  const dateOfWeekFormat = "EEEE";
  const dateOfWeekFormatShort = "EEE";

  const isTabletAndAbove = useResponsive(theme.breakpoints.up("desktop"), true);

  return (
    <Container className={className}>
      <ClientSideSuspense fallback={<></>}>
        {edition === EditionEnum.HongKong && (
          <>
            <DisplayDate>
              <b>
                {isTabletAndAbove
                  ? formatInTimeZone(new Date(), dateOfWeekFormat)
                  : formatInTimeZone(new Date(), dateOfWeekFormatShort)}
              </b>
              <span>
                {isTabletAndAbove
                  ? formatInTimeZone(new Date(), dateFormat)
                  : formatInTimeZone(new Date(), dateFormatShort)}
              </span>
            </DisplayDate>
            <ErrorBoundary fallback={<></>}>
              <HeaderWeather />
            </ErrorBoundary>
          </>
        )}
      </ClientSideSuspense>
    </Container>
  );
};

DateWeather.displayName = "DateWeather";
